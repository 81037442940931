@import url('https://fonts.googleapis.com/css?family=Playfair+Display');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display|Source+Sans+Pro');

$card-size: 400px;
$image-size: 40%;
$image-height: 250px;


.card {
  width: 800px;
  margin: 8% auto;

  // background-color: white;
  // background-color: rgba(118, 115, 115, 0.815);
  background-color: #ccc;
  box-shadow: 0px 5px 20px #000;

  a {
    color: #333;
    text-decoration: none;
  }

  // &:hover {  
  //   .card-image {
  //     img {
  //        width: $image-size+10%;
  //        filter:grayscale(0);
  //     }  
  //   }
  // }

}

.card-image {

  background: #223;
  height: 290px;
  position: relative;
  overflow: hidden;

  img {
    margin: 10px 0px;
    width: $image-size;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    // filter: grayscale(1);

    transition-property: filter width;
    transition-duration: .3s;
  }

  &:hover {

    img {
      width: $image-size+10%;
      // filter: grayscale(0);
    }

  }

}

.card-body {
  color: #333;
  text-align: justify;
  padding: 15px 20px;
  box-sizing: border-box;
}

.card-body p {
  color: #333;
}

.card-date {
  font-family: 'Source Sans Pro', sans-serif;
}

.card-title,
.card-exceprt {
  font-family: 'Playfair Display', serif;
}

.card-date,
.card-title {
  text-align: center;
  // text-align: left;
  text-transform: uppercase;
  font-weight: bold;
}

.card-date {
  color: rgba(51, 51, 51, 0.89);
}

.card-excerpt {
  // color: #777;
  color: #000;
}