* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.visionAndMission{
    background: linear-gradient(-45deg, #1B2430, #51557E, #232b2b);
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
}
.mainVM {
    // background-color: #aaa;
    padding: 1rem 0rem 0.1rem 0rem;
}

.navbar-lists {
    list-style: none;
    display: flex;
    gap: 3rem;
}

.navbar-lists > li {
    list-style: none;
}

.header {
    padding: 1.3rem 4.8rem;
    // height: 1rem;
    // background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    height: 6rem;
}

.navbar-lists {
    display: flex;
    gap: 4.8rem;
}

.navbar-link:link,
.navbar-link:visited {
    display: inline-block;
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    // color: #212529;
    color: #fff;
    transition: color 0.3s linear;
}

.navbar-link:hover,
.navbar-link:active {
    color: #8490ff;
}
section:nth-child(odd){
    margin: 0rem 0rem 1rem 3rem;
}
section:nth-child(even){
    margin: 0rem 3rem 1rem 0rem;
}
/* Hero section  */
.section {
    padding: 2rem 0;
    // background: #0605108a;

}

// .hero-container {
//     max-width: 140rem;
// }

.grid {
    display: grid;
    // display: flex;
    gap: 9rem;
}

.grid-two-column {
    grid-template-columns: 1fr 1fr;
    // flex-direction: row;
}


.section-hero-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.hero-data-container {
    display: inline-block;
    // padding: 23px 29px;
    font-size: 1.3rem;
    line-height: 1.5;
    padding: 20px 20px 20px 20px;
    border-radius: 12px;
    // border-top-left-radius: 12px;
    // border-bottom-left-radius: 12px;
    // border-top-right-radius: 12px;
    // border-bottom-right-radius: 12px;
    background-color: rgba(255, 255, 255, .15);
    backdrop-filter: blur(50px);
    color: #fff;
}


// .align-left {
    // align-items: flex-start;
// }

// .align-right {
    // align-items: flex-end;
// }

// .hero-para {
    // color: #777;
// }

// .hero-data-left {
    // padding-left: 13rem;
    // padding-left: 0rem;
// }

.hero-top-data {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.5rem;
    // color: #0d22e3;
}

.hero-heading {
    text-transform: uppercase;
    // color: rgb(0, 10, 45);
    padding: 0.5rem 0;
    font-weight: 700;
    font-size: 1.8rem;
}

.hero-para {
    margin-top: 1rem;
    margin-bottom: 5rem;
    max-width: 60rem;
}

.section-hero-image {
    display: flex;
    justify-content: center;
    align-items: center;

}

.hero-img {
    max-width: 80%;

}

#forDesign {
    height: 40px;
}

@media screen and (max-width: 768px) {
    .header {
        padding: 1rem 1rem;
    }

    // .navbar{
    // display: none;
    // }
    .navbar-lists {
        gap: 1rem;
    }

    .navbar-link:link,
    .navbar-link:visited {
        font-size: 0.9rem;
    }
section:nth-child(n){
    margin: 0rem 0rem 1rem 0rem;
}
    .section-hero-data {
        align-items: center;
    }

    .grid {
        display: flex;
        gap: 2rem;
        flex-direction: column;
    }

    .reverse {
        flex-direction: column-reverse;
    }

    .hero-data-left {
        // padding-left: 13rem;
        padding-left: 0rem;
    }

    // .grid-two-column {
        // direction: rtl;
        // grid-template-columns: 1fr;
    // }
}