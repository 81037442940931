.shelf {
  width: 1000px;
  height: 8px;
  margin: auto;
  border-radius: 1px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-bottom: 10px solid chocolate;
  border-top: 80px solid transparent;
}
.shelf:hover {
  animation: shelf-lift 0.5s ease;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  cursor: pointer;
}
.shelf:hover .book {
  animation: book-bounce 0.5s ease;
  animation-iteration-count: 1;
}
@media screen and (max-width: 578px) {
  .shelf {
    width: 750px;
  }
}
.book {
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 2px;
  font-size: 20px;
  overflow: hidden;
  line-height: 15%;
  text-align: center;
  font-weight: lighter;
}
.book:nth-child(0):before {
  content: "";
  color: #111111;
}
.book:nth-child(0) {
  height: 47px;
  animation-delay: 0s !important;
  background-color: #935357;
  width: 10px;
}
.book:nth-child(1):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(1) {
  height: 69px;
  animation-delay: 0.002s !important;
  background-color: #ffe4e1;
  width: 9px;
}
.book:nth-child(2):before {
  content: "";
  color: #111111;
}
.book:nth-child(2) {
  height: 46px;
  animation-delay: 0.004s !important;
  background-color: #bada55;
  width: 8px;
}
.book:nth-child(3):before {
  content: "---";
  color: #111111;
}
.book:nth-child(3) {
  height: 51px;
  animation-delay: 0.006s !important;
  background-color: #e7d5c9;
  width: 10px;
}
.book:nth-child(4):before {
  content: "";
  color: #111111;
}
.book:nth-child(4) {
  height: 61px;
  animation-delay: 0.008s !important;
  background-color: #cbc2bf;
  width: 10px;
}
.book:nth-child(5):before {
  content: "------------------------";
  color: #eeeeee;
}
.book:nth-child(5) {
  height: 51px;
  animation-delay: 0.01s !important;
  background-color: #e9d7c7;
  width: 8px;
}
.book:nth-child(6):before {
  content: "--";
  color: #eeeeee;
}
.book:nth-child(6) {
  height: 50px;
  animation-delay: 0.012s !important;
  background-color: #666666;
  width: 10px;
}
.book:nth-child(7):before {
  content: "";
  color: #111111;
}
.book:nth-child(7) {
  height: 67px;
  animation-delay: 0.014s !important;
  background-color: #90b19c;
  width: 8px;
}
.book:nth-child(8):before {
  content: "";
  color: #111111;
}
.book:nth-child(8) {
  height: 70px;
  animation-delay: 0.016s !important;
  background-color: #ff9966;
  width: 11px;
}
.book:nth-child(9):before {
  content: "---";
  color: #111111;
}
.book:nth-child(9) {
  height: 54px;
  animation-delay: 0.018s !important;
  background-color: #f1ab4f;
  width: 8px;
}
.book:nth-child(10):before {
  content: "---";
  color: #111111;
}
.book:nth-child(10) {
  height: 42px;
  animation-delay: 0.02s !important;
  background-color: #00b8ff;
  width: 11px;
}
.book:nth-child(11):before {
  content: "---";
  color: #111111;
}
.book:nth-child(11) {
  height: 49px;
  animation-delay: 0.022s !important;
  background-color: #008080;
  width: 11px;
}
.book:nth-child(12):before {
  content: "------------------------";
  color: #111111;
}
.book:nth-child(12) {
  height: 66px;
  animation-delay: 0.024s !important;
  background-color: #52aca2;
  width: 10px;
}
.book:nth-child(13):before {
  content: "---";
  color: #111111;
}
.book:nth-child(13) {
  height: 50px;
  animation-delay: 0.026s !important;
  background-color: #f6c9b9;
  width: 9px;
}
.book:nth-child(14):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(14) {
  height: 61px;
  animation-delay: 0.028s !important;
  background-color: #b190a5;
  width: 10px;
}
.book:nth-child(15):before {
  content: "--";
  color: #eeeeee;
}
.book:nth-child(15) {
  height: 56px;
  animation-delay: 0.03s !important;
  background-color: #aa5e40;
  width: 9px;
}
.book:nth-child(16):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(16) {
  height: 51px;
  animation-delay: 0.032s !important;
  background-color: #ff9966;
  width: 8px;
}
.book:nth-child(17):before {
  content: "---";
  color: #eeeeee;
}
.book:nth-child(17) {
  height: 58px;
  animation-delay: 0.034s !important;
  background-color: #999999;
  width: 11px;
}
.book:nth-child(18):before {
  content: "---";
  color: #eeeeee;
}
.book:nth-child(18) {
  height: 45px;
  animation-delay: 0.036s !important;
  background-color: #998888;
  width: 9px;
}
.book:nth-child(19):before {
  content: "--";
  color: #eeeeee;
}
.book:nth-child(19) {
  height: 53px;
  animation-delay: 0.038s !important;
  background-color: #01f6f1;
  width: 11px;
}
.book:nth-child(20):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(20) {
  height: 43px;
  animation-delay: 0.04s !important;
  background-color: #062542;
  width: 9px;
}
.book:nth-child(21):before {
  content: "";
  color: #111111;
}
.book:nth-child(21) {
  height: 55px;
  animation-delay: 0.042s !important;
  background-color: #ffda4f;
  width: 10px;
}
.book:nth-child(22):before {
  content: "";
  color: #111111;
}
.book:nth-child(22) {
  height: 66px;
  animation-delay: 0.044s !important;
  background-color: #ced5df;
  width: 11px;
}
.book:nth-child(23):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(23) {
  height: 48px;
  animation-delay: 0.046s !important;
  background-color: #000000;
  width: 10px;
}
.book:nth-child(24):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(24) {
  height: 65px;
  animation-delay: 0.048s !important;
  background-color: #998888;
  width: 9px;
}
.book:nth-child(25):before {
  content: "--";
  color: #eeeeee;
}
.book:nth-child(25) {
  height: 62px;
  animation-delay: 0.05s !important;
  background-color: #aa5e40;
  width: 9px;
}
.book:nth-child(26):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(26) {
  height: 48px;
  animation-delay: 0.052s !important;
  background-color: #fdbdb8;
  width: 8px;
}
.book:nth-child(27):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(27) {
  height: 59px;
  animation-delay: 0.054s !important;
  background-color: #ffda4f;
  width: 11px;
}
.book:nth-child(28):before {
  content: "---";
  color: #111111;
}
.book:nth-child(28) {
  height: 58px;
  animation-delay: 0.056s !important;
  background-color: #000000;
  width: 11px;
}
.book:nth-child(29):before {
  content: "";
  color: #111111;
}
.book:nth-child(29) {
  height: 69px;
  animation-delay: 0.058s !important;
  background-color: #998888;
  width: 8px;
}
.book:nth-child(30):before {
  content: "--";
  color: #111111;
}
.book:nth-child(30) {
  height: 50px;
  animation-delay: 0.06s !important;
  background-color: #ecc2bf;
  width: 11px;
}
.book:nth-child(31):before {
  content: "--";
  color: #111111;
}
.book:nth-child(31) {
  height: 62px;
  animation-delay: 0.062s !important;
  background-color: #00b8ff;
  width: 9px;
}
.book:nth-child(32):before {
  content: "------------------------";
  color: #111111;
}
.book:nth-child(32) {
  height: 58px;
  animation-delay: 0.064s !important;
  background-color: #dbdcff;
  width: 11px;
}
.book:nth-child(33):before {
  content: "";
  color: #111111;
}
.book:nth-child(33) {
  height: 60px;
  animation-delay: 0.066s !important;
  background-color: #93786f;
  width: 9px;
}
.book:nth-child(34):before {
  content: "";
  color: #111111;
}
.book:nth-child(34) {
  height: 50px;
  animation-delay: 0.068s !important;
  background-color: #86516b;
  width: 8px;
}
.book:nth-child(35):before {
  content: "--";
  color: #111111;
}
.book:nth-child(35) {
  height: 49px;
  animation-delay: 0.07s !important;
  background-color: #bada55;
  width: 11px;
}
.book:nth-child(36):before {
  content: "";
  color: #111111;
}
.book:nth-child(36) {
  height: 52px;
  animation-delay: 0.072s !important;
  background-color: #a2697d;
  width: 11px;
}
.book:nth-child(37):before {
  content: "";
  color: #111111;
}
.book:nth-child(37) {
  height: 45px;
  animation-delay: 0.074s !important;
  background-color: #008080;
  width: 10px;
}
.book:nth-child(38):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(38) {
  height: 62px;
  animation-delay: 0.076s !important;
  background-color: #935357;
  width: 9px;
}
.book:nth-child(39):before {
  content: "---";
  color: #eeeeee;
}
.book:nth-child(39) {
  height: 67px;
  animation-delay: 0.078s !important;
  background-color: #ecc2bf;
  width: 10px;
}
.book:nth-child(40):before {
  content: "------------------------";
  color: #111111;
}
.book:nth-child(40) {
  height: 56px;
  animation-delay: 0.08s !important;
  background-color: #f6c9b9;
  width: 10px;
}
.book:nth-child(41):before {
  content: "";
  color: #111111;
}
.book:nth-child(41) {
  height: 55px;
  animation-delay: 0.082s !important;
  background-color: #489cbd;
  width: 10px;
}
.book:nth-child(42):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(42) {
  height: 50px;
  animation-delay: 0.084s !important;
  background-color: #78d663;
  width: 11px;
}
.book:nth-child(43):before {
  content: "---";
  color: #111111;
}
.book:nth-child(43) {
  height: 65px;
  animation-delay: 0.086s !important;
  background-color: #062542;
  width: 9px;
}
.book:nth-child(44):before {
  content: "";
  color: #111111;
}
.book:nth-child(44) {
  height: 56px;
  animation-delay: 0.088s !important;
  background-color: #a2697d;
  width: 8px;
}
.book:nth-child(45):before {
  content: "--";
  color: #eeeeee;
}
.book:nth-child(45) {
  height: 64px;
  animation-delay: 0.09s !important;
  background-color: #aa7d42;
  width: 9px;
}
.book:nth-child(46):before {
  content: "--";
  color: #eeeeee;
}
.book:nth-child(46) {
  height: 66px;
  animation-delay: 0.092s !important;
  background-color: #feffa3;
  width: 8px;
}
.book:nth-child(47):before {
  content: "--";
  color: #111111;
}
.book:nth-child(47) {
  height: 62px;
  animation-delay: 0.094s !important;
  background-color: #aa7d42;
  width: 9px;
}
.book:nth-child(48):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(48) {
  height: 56px;
  animation-delay: 0.096s !important;
  background-color: #a52a5e;
  width: 11px;
}
.book:nth-child(49):before {
  content: "--";
  color: #111111;
}
.book:nth-child(49) {
  height: 62px;
  animation-delay: 0.098s !important;
  background-color: #01f6f1;
  width: 10px;
}
.book:nth-child(50):before {
  content: "";
  color: #111111;
}
.book:nth-child(50) {
  height: 59px;
  animation-delay: 0.1s !important;
  background-color: #e9d7c7;
  width: 9px;
}
.book:nth-child(51):before {
  content: "------------------------";
  color: #eeeeee;
}
.book:nth-child(51) {
  height: 53px;
  animation-delay: 0.102s !important;
  background-color: #bada55;
  width: 9px;
}
.book:nth-child(52):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(52) {
  height: 58px;
  animation-delay: 0.104s !important;
  background-color: #000000;
  width: 11px;
}
.book:nth-child(53):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(53) {
  height: 63px;
  animation-delay: 0.106s !important;
  background-color: #93786f;
  width: 10px;
}
.book:nth-child(54):before {
  content: "--";
  color: #111111;
}
.book:nth-child(54) {
  height: 54px;
  animation-delay: 0.108s !important;
  background-color: #dbdcff;
  width: 8px;
}
.book:nth-child(55):before {
  content: "---";
  color: #111111;
}
.book:nth-child(55) {
  height: 63px;
  animation-delay: 0.11s !important;
  background-color: #935357;
  width: 10px;
}
.book:nth-child(56):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(56) {
  height: 67px;
  animation-delay: 0.112s !important;
  background-color: #935357;
  width: 9px;
}
.book:nth-child(57):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(57) {
  height: 55px;
  animation-delay: 0.114s !important;
  background-color: #f7850a;
  width: 10px;
}
.book:nth-child(58):before {
  content: "--";
  color: #111111;
}
.book:nth-child(58) {
  height: 63px;
  animation-delay: 0.116s !important;
  background-color: #90b19c;
  width: 9px;
}
.book:nth-child(59):before {
  content: "";
  color: #111111;
}
.book:nth-child(59) {
  height: 59px;
  animation-delay: 0.118s !important;
  background-color: #062542;
  width: 10px;
}
.book:nth-child(60):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(60) {
  height: 55px;
  animation-delay: 0.12s !important;
  background-color: #78d663;
  width: 10px;
}
.book:nth-child(61):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(61) {
  height: 48px;
  animation-delay: 0.122s !important;
  background-color: #b190a5;
  width: 9px;
}
.book:nth-child(62):before {
  content: "";
  color: #111111;
}
.book:nth-child(62) {
  height: 61px;
  animation-delay: 0.124s !important;
  background-color: #bada55;
  width: 9px;
}
.book:nth-child(63):before {
  content: "---";
  color: #eeeeee;
}
.book:nth-child(63) {
  height: 55px;
  animation-delay: 0.126s !important;
  background-color: #489cbd;
  width: 8px;
}
.book:nth-child(64):before {
  content: "--";
  color: #111111;
}
.book:nth-child(64) {
  height: 42px;
  animation-delay: 0.128s !important;
  background-color: #bada55;
  width: 8px;
}
.book:nth-child(65):before {
  content: "--";
  color: #eeeeee;
}
.book:nth-child(65) {
  height: 53px;
  animation-delay: 0.13s !important;
  background-color: #ffda4f;
  width: 11px;
}
.book:nth-child(66):before {
  content: "--";
  color: #111111;
}
.book:nth-child(66) {
  height: 67px;
  animation-delay: 0.132s !important;
  background-color: #008080;
  width: 10px;
}
.book:nth-child(67):before {
  content: "";
  color: #111111;
}
.book:nth-child(67) {
  height: 62px;
  animation-delay: 0.134s !important;
  background-color: #86516b;
  width: 10px;
}
.book:nth-child(68):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(68) {
  height: 67px;
  animation-delay: 0.136s !important;
  background-color: #e9d7c7;
  width: 11px;
}
.book:nth-child(69):before {
  content: "--";
  color: #111111;
}
.book:nth-child(69) {
  height: 62px;
  animation-delay: 0.138s !important;
  background-color: #bada55;
  width: 9px;
}
.book:nth-child(70):before {
  content: "";
  color: #111111;
}
.book:nth-child(70) {
  height: 45px;
  animation-delay: 0.14s !important;
  background-color: #666666;
  width: 11px;
}
.book:nth-child(71):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(71) {
  height: 51px;
  animation-delay: 0.142s !important;
  background-color: #cbc2bf;
  width: 9px;
}
.book:nth-child(72):before {
  content: "---";
  color: #111111;
}
.book:nth-child(72) {
  height: 66px;
  animation-delay: 0.144s !important;
  background-color: #bada55;
  width: 11px;
}
.book:nth-child(73):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(73) {
  height: 56px;
  animation-delay: 0.146s !important;
  background-color: #a52a5e;
  width: 9px;
}
.book:nth-child(74):before {
  content: "---";
  color: #111111;
}
.book:nth-child(74) {
  height: 50px;
  animation-delay: 0.148s !important;
  background-color: #bada55;
  width: 9px;
}
.book:nth-child(75):before {
  content: "";
  color: #111111;
}
.book:nth-child(75) {
  height: 60px;
  animation-delay: 0.15s !important;
  background-color: #86516b;
  width: 9px;
}
.book:nth-child(76):before {
  content: "--";
  color: #111111;
}
.book:nth-child(76) {
  height: 69px;
  animation-delay: 0.152s !important;
  background-color: #935357;
  width: 9px;
}
.book:nth-child(77):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(77) {
  height: 58px;
  animation-delay: 0.154s !important;
  background-color: #aa5e40;
  width: 10px;
}
.book:nth-child(78):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(78) {
  height: 43px;
  animation-delay: 0.156s !important;
  background-color: #aa5e40;
  width: 8px;
}
.book:nth-child(79):before {
  content: "---";
  color: #111111;
}
.book:nth-child(79) {
  height: 48px;
  animation-delay: 0.158s !important;
  background-color: #bada55;
  width: 9px;
}
.book:nth-child(80):before {
  content: "";
  color: #111111;
}
.book:nth-child(80) {
  height: 56px;
  animation-delay: 0.16s !important;
  background-color: #bada55;
  width: 10px;
}
.book:nth-child(81):before {
  content: "";
  color: #111111;
}
.book:nth-child(81) {
  height: 43px;
  animation-delay: 0.162s !important;
  background-color: #489cbd;
  width: 8px;
}
.book:nth-child(82):before {
  content: "";
  color: #111111;
}
.book:nth-child(82) {
  height: 48px;
  animation-delay: 0.164s !important;
  background-color: #f7850a;
  width: 8px;
}
.book:nth-child(83):before {
  content: "---";
  color: #111111;
}
.book:nth-child(83) {
  height: 70px;
  animation-delay: 0.166s !important;
  background-color: #86516b;
  width: 9px;
}
.book:nth-child(84):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(84) {
  height: 41px;
  animation-delay: 0.168s !important;
  background-color: #bada55;
  width: 9px;
}
.book:nth-child(85):before {
  content: "";
  color: #111111;
}
.book:nth-child(85) {
  height: 56px;
  animation-delay: 0.17s !important;
  background-color: #ecc2bf;
  width: 8px;
}
.book:nth-child(86):before {
  content: "";
  color: #111111;
}
.book:nth-child(86) {
  height: 68px;
  animation-delay: 0.172s !important;
  background-color: #f04f6b;
  width: 8px;
}
.book:nth-child(87):before {
  content: "";
  color: #111111;
}
.book:nth-child(87) {
  height: 44px;
  animation-delay: 0.174s !important;
  background-color: #78d663;
  width: 8px;
}
.book:nth-child(88):before {
  content: "---";
  color: #111111;
}
.book:nth-child(88) {
  height: 50px;
  animation-delay: 0.176s !important;
  background-color: #52aca2;
  width: 9px;
}
.book:nth-child(89):before {
  content: "";
  color: #111111;
}
.book:nth-child(89) {
  height: 51px;
  animation-delay: 0.178s !important;
  background-color: #fdbdb8;
  width: 9px;
}
.book:nth-child(90):before {
  content: "--";
  color: #111111;
}
.book:nth-child(90) {
  height: 64px;
  animation-delay: 0.18s !important;
  background-color: #aa5e40;
  width: 9px;
}
.book:nth-child(91):before {
  content: "---";
  color: #eeeeee;
}
.book:nth-child(91) {
  height: 44px;
  animation-delay: 0.182s !important;
  background-color: #f04f6b;
  width: 11px;
}
.book:nth-child(92):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(92) {
  height: 44px;
  animation-delay: 0.184s !important;
  background-color: #90b19c;
  width: 10px;
}
.book:nth-child(93):before {
  content: "";
  color: #111111;
}
.book:nth-child(93) {
  height: 68px;
  animation-delay: 0.186s !important;
  background-color: #ffc0cb;
  width: 11px;
}
.book:nth-child(94):before {
  content: "---";
  color: #111111;
}
.book:nth-child(94) {
  height: 63px;
  animation-delay: 0.188s !important;
  background-color: #cbc2bf;
  width: 10px;
}
.book:nth-child(95):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(95) {
  height: 46px;
  animation-delay: 0.19s !important;
  background-color: #666666;
  width: 8px;
}
.book:nth-child(96):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(96) {
  height: 46px;
  animation-delay: 0.192s !important;
  background-color: #bada55;
  width: 11px;
}
.book:nth-child(97):before {
  content: "";
  color: #eeeeee;
}
.book:nth-child(97) {
  height: 60px;
  animation-delay: 0.194s !important;
  background-color: #dbdcff;
  width: 8px;
}
.book:nth-child(98):before {
  content: "---";
  color: #eeeeee;
}
.book:nth-child(98) {
  height: 43px;
  animation-delay: 0.196s !important;
  background-color: #90b19c;
  width: 11px;
}
.book:nth-child(99):before {
  content: "---";
  color: #111111;
}
.book:nth-child(99) {
  height: 49px;
  animation-delay: 0.198s !important;
  background-color: #ced5df;
  width: 10px;
}
@keyframes book-bounce {
  0% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  80% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes shelf-lift {
  0% {
    transform: translateY(0) rotate(0);
  }
  20% {
    transform: translateY(-5px) rotate(2deg);
  }
  40% {
    transform: translateY(-10px) rotate(0);
  }
  60% {
    transform: translateY(-10px) rotate(-2deg);
  }
  80% {
    transform: translateY(-5px) rotate(-1deg);
  }
  100% {
    transform: translateY(0);
  }
}
